import styled from "styled-components/macro"
import { Heading, Box, Paragraph } from "~styled"

const SectionTitle = styled(Box)``
SectionTitle.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
  color: inherit;
`
SectionTitle.Subtitle = styled(Heading)`
  color: var(--bs-secondary);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  position: relative;
  display: inline-block;
`
SectionTitle.Main = styled(Heading)`
  font-size: 30px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38095;

  @media (min-width: 768px) {
    font-size: 34px;
  }

  @media (min-width: 1200px) {
    font-size: 38px;
  }
`

SectionTitle.Price = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
  color: var(--bs-shark);
`

export default SectionTitle
