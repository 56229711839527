import styled from "styled-components/macro"
import { Box, Heading, Paragraph, Button } from "~styled"

const HeroFull = styled(Box)`
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  flex-direction: column;
  object-fit: cover;
  object-position: 50% 50% !important;
  z-index: 1;
  @media (min-width: 768px) {
    padding-top: 120px;
  }
  /* @media (min-width: 768px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "200px")};
    padding-bottom: 0px;
  }
  @media (min-width: 992px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "200px")};
    padding-bottom: 00px;
  }
  @media (min-width: 1200px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "300px")};
    padding-bottom: 0px;
  } */

  height: ${(props) => (props.adsPage ? "100%" : props.height || "50vh")};
  display: flex;
  align-items: center;
  color: white;
`

HeroFull.Subtitle = styled(Heading)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
`
HeroFull.Title = styled(Heading)`
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 21px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 992px) {
    font-size: 44px;
  }
`
HeroFull.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 10px;
`
HeroFull.Content = styled(Box)`
  position: relative !important;
  margin-top: 0;
  margin-top: 20px;
  @media (min-width: 992px) {
  }
`
HeroFull.Video = styled(Box)`
  position: relative;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 40px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  a {
    color: #b8a48d;
  }
  .video-block {
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    box-shadow: none;
    text-align: left;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    i {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      box-shadow: 12px 20px 50px rgba(0, 0, 0, 0.2);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #b8a48d;
      margin-right: 20px;
      color: #fff;
    }

    .btn-text {
      color: #b8a48d;
      transition: 0.4s;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 22px;
    }

    &:hover {
      .btn-text {
        text-decoration: underline;
      }
    }
  }
`

HeroFull.ThemeOverlay = styled(Box)`
  position: absolute;
  top: 0;
  /* &::before {
    content: "";
    position: fixed;
    opacity: 1;
    left: 0;
    top: 0;
    background-color: ${(props) => (props.color ? props.color : "transparent")};
    width: 100%;
    height: 100vh;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  } */
  &::after {
    content: "";
    position: absolute;
    opacity: 1;
    left: 0;
    top: 0;
    background-color: ${(props) => (props.color ? props.color : "transparent")};
    /* width: 100vw; */
    /* height: 100vh; */
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
`

HeroFull.ButtonGroup = styled(Box)`
  justify-content: center;
  margin-right: -17.0656px;
  display: flex;
  flex-wrap: wrap;
`

HeroFull.Button = styled(Button)`
  margin-right: 15px;
  border-radius: 500px;
  color: #fff;
  min-width: 140px;
  height: 56px;
  margin-top: 15px;
  font-size: 14px;
  @media (min-width: 576px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    min-width: 165px;
    height: 60px;
  }
  &.btn-1 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
  &.btn-2 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
`
HeroFull.Box = styled(Box)``
export default HeroFull
