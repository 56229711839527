import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import HeroFull from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Images from "~data/imageImports"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import Vimeo from "@u-wave/react-vimeo"
import { Link } from "~components"

var settings = {
  touchDisabled: true,
  autoplay: 3000,
  infinite: true,
  minSwipeOffset: 20,
  duration: 2000,
}

export default function HeroFullSection({
  children,
  frontmatter,
  height,
  adsPage,
  servicePage,
  homePage,
  color,
  vimeoId,
  ctaText1,
  ctaLink1,
}) {
  return (
    <>
      <HeroFull
        // backgroundImage={frontmatter.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src}
        height={height}>
        {vimeoId && (
          <>
            <HeroFull.Video>
              <Vimeo
                video={vimeoId}
                responsive={true}
                // width={840}
                // height={200}
                autoplay
                volume={0}
                loop={true}
                background={true}
                controls={false}
                className="video-iframe"
                style={{
                  // borderRadius: "0px",
                  overflow: "hidden",
                  paddingTop: "0px",
                }}></Vimeo>
              <HeroFull.ThemeOverlay color="rgba(255,255,255,0.8)"></HeroFull.ThemeOverlay>
            </HeroFull.Video>
            <HeroFull.Content>
              <Container>
                <Row className="text-center">
                  <Col className="offset-md-2 col-md-8 col-xs-12">
                    <HeroFull.Content>
                      {children}
                      <HeroFull.Title as="h1" fontColor="#000">
                        {frontmatter.heroTitle || frontmatter.title}
                      </HeroFull.Title>
                      <HeroFull.Text as="p" fontColor="#000">
                        {frontmatter.description}
                      </HeroFull.Text>
                      <HeroFull.ButtonGroup mt="25px">
                        <HeroFull.Button
                          className="btn btn-primary rounded-pill py-3"
                          as={Link}
                          link={frontmatter.cta1}
                          target="_blank"
                          rel="noopener noreferrer">
                          {frontmatter.cta1Text}
                        </HeroFull.Button>
                      </HeroFull.ButtonGroup>
                    </HeroFull.Content>
                    <br />
                  </Col>
                </Row>
              </Container>
            </HeroFull.Content>
          </>
        )}
      </HeroFull>
    </>
  )
}
