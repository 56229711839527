import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { SEO } from "~components"
import { useSiteMetadata } from "~hooks/use-site-metadata"
import { Col, Container, Row, Button, Offcanvas } from "react-bootstrap"
import { PageWrapper, Link } from "~components"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { MDXButton } from "~components/MDXButton"
import OffcanvasStyle from "./style"

// import Related from "~components/Related"
import CtaSection from "~components/Cta"
import FooterBlock from "~components/FooterBlock"
import FooterSimple from "~components/FooterSimple"
import HeroFullSection from "~components/HeroFull"
import ContentSectionRight from "~components/ContentRight"
import ActionSection from "~components/Action/ActionSection"

// import HeroFullSection from "~sections/homepage/Hero"
// import AboutSection from "~sections/agency/About"
import About from "~components/About"
// import SectionTitle from "~components/About"

// import ServiceSection from "~sections/homepage/Service"
// import FeatureSection from "~sections/homepage/Features"
// import ContentSectionLeft from "~sections/homepage/ContentLeft"
// import ContentSectionTwo from "~sections/homepage/ContentTwo"
// import TestimonialSection from "~sections/app/Testimonial"
// import TreatmentsSection from "~sections/app/Treatments"

// import CounterSection from "~sections/homepage/Counter"
// import ActionSection from "~components/Action/ActionSection"
// import TeamSection from "~sections/digital/Team"

// mobile-sticky-enable
const header = {
  headerClasses: "site-header site-header--menu-end dark-header position-relative site-header--menu-sep",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary rounded-pill ms-auto ms-lg-0 d-none d-xs-inline-flex btn-sm"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function PromoPage({ data }) {
  const [show, setShow] = useState(false)
  const siteMetadata = useSiteMetadata()
  const { body, slug, frontmatter } = data.mdx
  const content = data.mdx
  const aboutImage = getImage(content.frontmatter.aboutImage)
  const heroImage = getImage(content.frontmatter.heroImage)
  const { title: siteTitle, siteUrl, siteLanguage, siteLocale } = useSiteMetadata()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const conditions = ["http", "tel:", "mail:"]

  const shortcodes = { Link, ContentSectionRight } // Provide common components here

  React.useEffect(() => {}, [])
  return (
    <>
      <PageWrapper headerConfig={header}>
        <SEO
          titleTemplate={`${siteTitle}`}
          titleSeparator={`-`}
          title={`${content.frontmatter.title}`}
          description={`${content.frontmatter.description || ""}`}
          type={`${siteMetadata.type}`}
          location={`${siteUrl}${slug}`}
          image={`${
            frontmatter?.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src ||
            frontmatter?.aboutImage?.childImageSharp.gatsbyImageData.images.fallback.src
          }`}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          keywords={content.frontmatter.keywords}
          indexed="true"
        />
        {content.frontmatter.heroImage && (
          <HeroFullSection
            frontmatter={content.frontmatter}
            slider={false}
            height={"100vh"}
            vimeoId="773063607"></HeroFullSection>
        )}
        {/* {aboutImage && (
          <ContentSectionRight
            image={
              <Img
                image={aboutImage || heroImage || null}
                imgClassName="w-100"
                className="w-100"
                objectFit="cover"
                objectPosition="50% 50%"
                loading="eager"
                imgStyle={{ borderRadius: 8, overflow: "hidden", boxShadow: "0 12px 40px 4px rgb(0 0 0 / 15%)" }}
                alt=""
              />
            }
            subtitle={content.frontmatter.category}
            title={content.frontmatter.title}
            description={content.frontmatter.description}
            ctaText1={content.frontmatter.cta1Text}
            ctaLink1={
              conditions.some((el) => content.frontmatter.cta1?.includes(el)) ||
              content.frontmatter.cta1?.startsWith("+")
                ? content.frontmatter.cta1
                : `https://www.vagaro.com/essencemedspaandwellnesscenter/book-now/${content.frontmatter.cta1}?partner=fb&ig_fbb=true`
            }
            ctaText2={content.frontmatter.cta2Text}
            ctaLink2={
              conditions.some((el) => content.frontmatter.cta2?.includes(el)) ||
              content.frontmatter.cta2?.startsWith("+")
                ? content.frontmatter.cta2
                : `https://www.vagaro.com/essencemedspaandwellnesscenter/book-now/${content.frontmatter.cta2}?partner=fb&ig_fbb=true`
            }
            cta1Price={content.frontmatter.cta1Price}
            cta2Price={content.frontmatter.cta2Price}
            video={""}
          />
        )} */}
        {/* <Button variant="primary" onClick={handleShow} className="btn btn-primary rounded-pill py-3 ms-auto me-auto">
          Book
        </Button> */}
        {content.frontmatter.category && <Related slug={slug} category={content.frontmatter.category} />}
        {/* {!content.frontmatter.category && <span className="py-5" />} */}
        {/* <Related slug={slug} category={content.frontmatter.category} /> */}
        {/* <ServiceSection /> */}
        {/* <FeatureSection /> */}
        {/* <ContentSectionOne /> */}
        {/* <ContentSectionTwo /> */}
        {/* <TestimonialSection /> */}
        {/* <TreatmentsSection /> */}
        {/* <CounterSection /> */}
        {/* <FormSection /> */}
        {/* <TeamSection /> */}
        {/* <CtaSection /> */}
        {/* <FooterBlock /> */}
        <FooterSimple />
      </PageWrapper>
    </>
  )
}

export const query = graphql`
  query PROMO_BY_SLUG($id: String!) {
    mdx(id: { eq: $id }) {
      id
      fields {
        slug
      }
      body
      excerpt
      frontmatter {
        status
        heroTag
        heroTitle
        heroText
        category
        title
        description
        image
        cta1
        cta1Text
        cta1Price
        cta2
        cta2Text
        cta2Price
        keywords
        heroImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
            original {
              src
            }
          }
        }
        aboutImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 440, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
            original {
              src
            }
          }
        }
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 600, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            original {
              src
            }
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
