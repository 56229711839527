import React from "react"
import ActionContainer from "./style"
import SectionTitleBlock from "~components/SectionTitle"
import { Col, Container, Row } from "react-bootstrap"
export default function ContactOne() {
  return (
    <ActionContainer id="book">
      <Container>
        <Row>
          <Col className="col-12">
            <ActionContainer.Box>
              <SectionTitleBlock
                // subTitle="Exclusive offer!"
                text="Fill in your info below, and we will find a Day/Time that best fits you!"
                subTitleProps={{ mb: "10px" }}
                titleProps={{ mb: "0px", as: "p" }}
                textProps={{ mb: "10px", as: "p", align: "center" }}
                mb="50px"
              />
            </ActionContainer.Box>
            <ActionContainer.From>
              <form action="./">
                <Row>
                  <Col xs="12" className="col-12 mb-4">
                    <div className="form-floating">
                      <input className="form-control" placeholder=" " id="floatinginput" name="firstName" />
                      <label htmlFor="floatinginput">First Name</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-12 mb-4">
                    <div className="form-floating">
                      <input className="form-control" placeholder=" " id="floatinginput" name="lastName" />
                      <label htmlFor="floatinginput">Last Name</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-12 mb-4">
                    <div className="form-floating">
                      <input className="form-control" placeholder=" " id="floatinginput" name="email" type="email" />
                      <label htmlFor="floatinginput">Email</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-12 mb-4">
                    <div className="form-floating">
                      <input className="form-control" placeholder=" " id="floatinginput2" name="phone" type="tel" />
                      <label htmlFor="floatinginput2">Phone number</label>
                    </div>
                  </Col>
                  {/* <Col xs="12" className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" placeholder=" " id="floatingTextarea3" />
                      <label htmlFor="floatingTextarea3">Your Message Here </label>
                      </div>
                  </Col> */}
                  <Col xs="12" className="col-12">
                    <Row className="align-items-center mt-3">
                      {/* <div className="col-12 pt-3">
                        <div className="form-check d-flex align-items-center">
                          <input
                          className="form-check-input bg-white float-none mt-0"
                            type="checkbox"
                            defaultValue
                            id="flexCheckDefault"
                            />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Your email address will not be published.
                          </label>
                        </div>
                      </div> */}
                      <Col xs="12" className="col-12">
                        <ActionContainer.Button>Submit</ActionContainer.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </ActionContainer.From>
            <ActionContainer.Box>
              <SectionTitleBlock
                // subTitle="Exclusive offer!"
                text="Or use Vagaro for self Booking"
                subTitleProps={{ mb: "10px", align: "center" }}
                titleProps={{ mb: "0px", as: "p", align: "center" }}
                textProps={{ mt: "10px", align: "center" }}
                mb="50px"
              />
            </ActionContainer.Box>
            <ActionContainer.Vagaro>Vagaro</ActionContainer.Vagaro>
          </Col>
        </Row>
      </Container>
    </ActionContainer>
  )
}
