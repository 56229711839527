import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const ContentStyle = styled(Box).attrs({ className: "related-card" })`
  position: relative;
  margin-bottom: 25px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 25px;
  .card-innerblock {
    position: absolute;
    left: 50%;
    bottom: 18px;
    width: 90%;
    transform: translateX(-50%);
    overflow: hidden;
    visibility: visible;
    opacity: 1;
  }
  &:hover {
    .card-innerblock {
      visibility: visible;
      opacity: 1;
    }
  }
`

ContentStyle.InnerBlock = styled(Box).attrs({ className: "card-innerblock" })`
  padding: 8px 16px;
  border-radius: 0;
  transition: all 0.7s ease-in-out;
  flex-direction: column;
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-icon {
    @media (min-width: 480px) {
      order: 2;
    }
  }
  .card-content {
    @media (min-width: 480px) {
      order: 1;
    }
  }
`

ContentStyle.Subtitle = styled(Heading)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`
ContentStyle.Title = styled(Heading)`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    font-size: 36px;
  }
  @media (min-width: 992px) {
    font-size: 36px;
  }
`
ContentStyle.Content = styled(Box)`
  position: relative !important;
  margin-top: 0;
  padding: 12px;
  text-align: center;
  @media (min-width: 992px) {
    margin-top: 80px;
  }
`

ContentStyle.Text = styled(Paragraph)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 0;
`

ContentStyle.InnerIcon = styled(Paragraph)`
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  i {
    margin: 0 10px;
    font-size: 18px;
    color: inherit;
  }
`

ContentStyle.SocialItem = styled(Box)`
  color: #fff;
  padding-right: 20px;
  transition-duration: 0.4s;
`
ContentStyle.Box = styled(Box)``

ContentStyle.Image = styled(Box)`
  width: 100%;
  min-height: 300px;
  border-radius: 8px;
  background-size: cover;
  position: relative;
  left: 0;
  bottom: 0px;
  padding: 30px;
  background-image: ${(props) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0)   50%, rgba(0, 0, 0, 0) 100%), url(${props.backgroundImage})`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 1s;
  color: #fff;
`

ContentStyle.OverlayBlock = styled(Box)`
  width: 100%;
  border-radius: 8px;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0px;
  padding: 30px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 1s;
  color: #fff;
  &:hover {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.4) 100%);
  }
`

export default ContentStyle
